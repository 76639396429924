#paperMask {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.4);
}

#paperMask svg {
    height: 72px;
    width: 72px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -36px 0 0 -36px;
}

#paperMask .g-circles {
    -webkit-transform: scale(0.9) translate(7px, 7px);
    -ms-transform: scale(0.9) translate(7px, 7px);
    transform: scale(0.9) translate(7px, 7px);
}

#paperMask circle {
    fill: #faad14;
    fill-opacity: 0;
    -webkit-animation: opacity 0.78s linear infinite;
    animation: opacity 0.78s linear infinite;
}

#paperMask circle:nth-child(12n + 1) {
    -webkit-animation-delay: -0.065s;
    animation-delay: -0.065s;
}

#paperMask circle:nth-child(12n + 2) {
    -webkit-animation-delay: -0.13s;
    animation-delay: -0.13s;
}

#paperMask circle:nth-child(12n + 3) {
    -webkit-animation-delay: -0.195s;
    animation-delay: -0.195s;
}

#paperMask circle:nth-child(12n + 4) {
    -webkit-animation-delay: -0.26s;
    animation-delay: -0.26s;
}

#paperMask circle:nth-child(12n + 5) {
    -webkit-animation-delay: -0.325s;
    animation-delay: -0.325s;
}

#paperMask circle:nth-child(12n + 6) {
    -webkit-animation-delay: -0.39s;
    animation-delay: -0.39s;
}

#paperMask circle:nth-child(12n + 7) {
    -webkit-animation-delay: -0.455s;
    animation-delay: -0.455s;
}

#paperMask circle:nth-child(12n + 8) {
    -webkit-animation-delay: -0.52s;
    animation-delay: -0.52s;
}

#paperMask circle:nth-child(12n + 9) {
    -webkit-animation-delay: -0.585s;
    animation-delay: -0.585s;
}

#paperMask circle:nth-child(12n + 10) {
    -webkit-animation-delay: -0.65s;
    animation-delay: -0.65s;
}

#paperMask circle:nth-child(12n + 11) {
    -webkit-animation-delay: -0.715s;
    animation-delay: -0.715s;
}

#paperMask circle:nth-child(12n + 12) {
    -webkit-animation-delay: -0.78s;
    animation-delay: -0.78s;
}

@-webkit-keyframes opacity {
    3% {
        fill-opacity: 1;
    }

    75% {
        fill-opacity: 0;
    }
}

@keyframes opacity {
    3% {
        fill-opacity: 1;
    }

    75% {
        fill-opacity: 0;
    }
}

.empty{
    padding:50px 0;
}
.empty p{
    margin-top: 20px;
    font-size: 16px;
    color:#ccc;
}
.empty img{
    width: 60px;
}