@charset "utf-8";
body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,form,input,button,textarea,p,table,th,td,section,img{
    margin:0;
    padding:0;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
article, aside, details, figcaption, figure, footer, header, menu, nav, section {
    display: block;
    margin:0;
    padding:0;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
ol,ul,li,dl,dt,dd {
    list-style:none;
}
button, input, select, textarea {
    font-family: Helvetica,Tahoma,Arial,"Helvetica Neue","Hiragino Sans GB","Microsoft YaHei";
    -webkit-appearance:none;
    -moz-appearance:none;
    appearance:none;
    outline: none;
    border-radius: 0;
    border: 1px solid #ccc;
}
i,em{
    font-style:normal;
}
img {
    border:none;
}
table{
    border-collapse:collapse;
    border-spacing:0;
}
html,body {
    height: 100%;
    background: #fff;
    font-size: 14px;
    line-height: 1.4;
    font-family: Helvetica,Tahoma,Arial,"Helvetica Neue","Hiragino Sans GB","Microsoft YaHei";
    color: #313135;
    -webkit-text-size-adjust:100%;
    -ms-text-size-adjust: 100%;
    text-rendering: optimizelegibility;
}
a,a:hover,a:link,a:visited,a:active {
    color: #313135;
    text-decoration:none;
}
p,.wordsBreak{
    word-wrap:break-word;
    word-break:break-all;
}
.clearfix:after {
    content: ".";
    display: block;
    height:0;
    clear:both;
    visibility: hidden;
}
.clearfix {
    *zoom:1;
}
.ellipsis{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}